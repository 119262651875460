import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { auth } from "../../firebase";
import moment from 'moment';
import authentication from "../../services/authentication";
import CssBaseline from '@material-ui/core/CssBaseline';
import { Button } from "@material-ui/core";
import Container from '@material-ui/core/Container';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { CSVReader } from 'react-papaparse';
import firebase, { firestore } from '../../firebase';
import "firebase/functions";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 1),
    maxWidth: 1200,
    marginLeft: 'auto',
    marginRight: 'auto',
    margin: theme.spacing(1),
  },
  nowrapCell: {
    whiteSpace: 'nowrap',
  },
  closeButton: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    padding: theme.spacing(1),
  },
  center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
  },
  metaInput: {
    marginBottom: 10,
  }
});

class LoadDataPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      metadata: null,
    }
    this.getData();

  }

  getData = () => {
    var that = this;
    var query = firestore.collection('metadata').doc('dataload');
    query.get()
    .then((metadataDoc) => {
      if(metadataDoc.exists){
        var metadata = metadataDoc.data();
        console.log(metadata);
        var oldestDate = moment(metadata.oldestDate);
        var newestDate = moment(metadata.newestDate);
        var difference = newestDate.diff(oldestDate, 'months');
        // console.log(oldestDate);
        // console.log(newestDate);
        // console.log(difference);
        var monthList = [];
        var i;
        for(i=0; i < difference + 1; i++){
          var monthYear = moment(metadata.oldestDate).add(i, 'months');
          //console.log(monthYear.format('YYYYMM'));
          monthList.push(monthYear.format('YYYYMM'));
        }
        //console.log(monthList);
        that.setState({metadata: metadata, dataMonths: monthList});
      } else {
        console.error("Unable to read metadata doc dataload does not exist.");
      }
    })
    .catch((error) => {
      console.error("Unable to read metadata: ", error);
    });
  }

  updateMetadata = () => {
   console.log('Update Metadata clicked!', this.state.metadata);
   var metadataRef = firestore.collection('metadata').doc('dataload');
   metadataRef.update(this.state.metadata).then(() => {console.log('Metadata Saved')});
  }

  handleLoadDateChange = (date) => {
    var currMeta = this.state.metadata;
    currMeta.loadDate = new firebase.firestore.Timestamp(moment(date).format('X'),0); 
    this.setState({ metadata: currMeta });
  }

  handleNewestDateChange = (date) => {
    var currMeta = this.state.metadata;

    var momentDate = moment(date);
    var formattedDate = momentDate.format('MM/DD/YYYY');

    currMeta.newestDate = formattedDate; 
    this.setState({ metadata: currMeta });
  }

  handleOldestDateChange = (date) => {
    var currMeta = this.state.metadata;

    var momentDate = moment(date);
    var formattedDate = momentDate.format('MM/DD/YYYY');

    currMeta.oldestDate = formattedDate; 
    this.setState({ metadata: currMeta });
  }


  handleChange = (event) => {
    console.log('Handle Change for Event: ', event.target.name, event.target.value);
    var metadata = this.state.metadata;
    metadata[event.target.name] = event.target.value;
    this.setState({metadata: metadata});
  }

  
  signInWithEmailLink = () => {
    const { user } = this.props;

    if (user) {
      return;
    }

    const emailLink = window.location.href;

    if (!emailLink) {
      return;
    }

    if (auth.isSignInWithEmailLink(emailLink)) {
      let emailAddress = localStorage.getItem("emailAddress");

      if (!emailAddress) {
        this.props.history.push("/");

        return;
      }

      authentication
        .signInWithEmailLink(emailAddress, emailLink)
        .then((value) => {
          const user = value.user;
          const displayName = user.displayName;
          const emailAddress = user.email;

          this.props.openSnackbar(
            `Signed in as ${displayName || emailAddress}`
          );
        })
        .catch((reason) => {
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            case "auth/expired-action-code":
            case "auth/invalid-email":
            case "auth/user-disabled":
              this.props.openSnackbar(message);
              break;

            default:
              this.props.openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          this.props.history.push("/");
        });
    }
  };

  // Function to convert 
  convertCurrencyToFloat = (currency) => { 
      
    if(currency){
      var temp = currency.replace(/[^0-9.-]+/g,""); 
      return parseFloat(temp); 
    } else {
      return(currency);
    }
      
  } 

  buildNameArray = () => {
    var updateAgentNameArrays = firebase.functions().httpsCallable('updateAgentNameArrays');
    
    //updateAgentNameArrays();

    updateAgentNameArrays().then(function(result){
      console.log('Update Agent Name Cloud Function Called:');
    });


    /*
    console.log('*** Build Agent Name Array Called ***');
    var agentArray = [];

    var query = firestore.collection('agents');
    query.get().then((snapshot) => {
        console.log('*** Build Agent Name Query Get Called ***');
        if(snapshot.size){
            snapshot.forEach((agentDoc) => {
                var agentObject = agentDoc.data();
                //console.log('Agent Doc: ', agentObject);
                var nameArray = agentObject.agentFullName.toString().toLowerCase().split(" ");
                var indexesToRemove = [];
                nameArray.forEach((value, index) =>{
                  if(value.length < 2){
                    indexesToRemove.push(index);
                  }
                });
                indexesToRemove.forEach((value, index) =>{
                  nameArray.splice(value, 1);
                });
                agentObject.nameArray = nameArray;
                //console.log('Updated Agent Object', agentObject);
                agentArray.push(agentObject);
            });  
            //return(true);  
            console.log('Agent Array', agentArray);
            //Chunk the array and then submit chunks to cloud functions

            var updateAgentNameArrays = firebase.functions().httpsCallable('updateAgentNameArrays');

            var i, j, agentsChunk, chunk = 100;
            for (i=0, j=agentArray.length; i < j; i += chunk) {
                agentsChunk = agentArray.slice(i,i+chunk);
                console.log('Calling UpdateAgentName Function with Chunk: ', agentsChunk);
                updateAgentNameArrays(agentsChunk).then(function(result){
                  console.log('Update Agent Name Cloud Function Called:');
                });
            }
        }
    }).catch((error) =>{
        console.error(error);
    });
    */

  }

  handleOnDrop = (data) => {
    console.log('-----------DROP------------');

    var listings = [];
    data.forEach((item, index) => {
      if(index > 0){

        try{

          var listingAgentPID = isNaN(item.data[3]) ? -1 : parseInt(item.data[3]); //CONVERT TO INTEGER
          var buyersAgentPID = isNaN(item.data[5]) ? -1 : parseInt(item.data[5]); //CONVERT TO INTEGER

          //var dom = isNaN(item.data[35]) ? 0 : parseInt(item.data[35]); //CONVERT TO INTEGER

          var listing = {
            'mls': item.data[1],
            'area': item.data[2],
            'listingAgentPID': listingAgentPID,
            'listingAgentFullName': item.data[4],
            'buyersAgentPID': buyersAgentPID,
            'buyersAgentFullName': item.data[6],
            'listingAgentEmail': item.data[7],
            'listingAgentOfficeName': item.data[8],
            'listingAgentDirectPhone': item.data[9],
            'zipCode': item.data[10],
            'listingOfficeCode': item.data[11],
            'communityName': item.data[13],
            'buyersOfficeCode': item.data[14],
            'subdivisionName': item.data[15],
            'buildingDescription': item.data[16],
            'buildingSubType': item.data[17],
            //'status': item.data[18],
            'address': item.data[19],
            'unitNumber': item.data[20],
            'bedrooms': this.convertCurrencyToFloat(item.data[21]),
            'bathrooms': this.convertCurrencyToFloat(item.data[22]),
            'garage': this.convertCurrencyToFloat(item.data[23]),
            'livingArea': this.convertCurrencyToFloat(item.data[24]),
            'lotArea': this.convertCurrencyToFloat(item.data[25]),
            'privatePool': item.data[26],
            'privateSpa': item.data[27],
            'listPricePerSqFt': this.convertCurrencyToFloat(item.data[28]),
            'soldPricePerSqFt': this.convertCurrencyToFloat(item.data[29]),
            'originalListPrice': this.convertCurrencyToFloat(item.data[30]),
            'currentListPrice': this.convertCurrencyToFloat(item.data[31]),
            'closePrice': this.convertCurrencyToFloat(item.data[32]),
            //'daysOnMarket': dom,
            'daysOnMarket': this.convertCurrencyToFloat(item.data[35]),
            'buyersAgentDirectPhone': item.data[36],
            'agentToAgentRemarks': item.data[37],
            'agents': [listingAgentPID, buyersAgentPID],

            //'listDate': moment(item.data[12], "M/D/YY").toDate(), //CONVERT TO DATE
            //'actualCloseDate': moment(item.data[33], "M/D/YY").toDate(), //CONVERT TO DATE
            //'estimatedCloseDate': moment(item.data[34], "M/D/YY").toDate() //CONVERT TO DATE

            'listDate': firebase.firestore.Timestamp.fromDate(moment(item.data[12]).toDate()), //CONVERT TO DATE
            'actualCloseDate': firebase.firestore.Timestamp.fromDate(moment(item.data[33]).toDate()), //CONVERT TO DATE
            'estimatedCloseDate': firebase.firestore.Timestamp.fromDate(moment(item.data[34]).toDate()) //CONVERT TO DATE
          }

          listings.push(listing);
        } catch (error) {
          console.error("Error Parsing Listing: ", item);
        }
      }
    });

    console.log("Listings: ", listings);

    console.log('---------------------------');

    /* */
    var addListings = firebase.functions().httpsCallable('addListings');

    var i, j, listingsChunk, chunk = 100;
    for (i=0, j=listings.length; i < j; i += chunk) {
        listingsChunk = listings.slice(i,i+chunk);
        console.log('Calling Add Listings Function with Chunk: ', listingsChunk);
        addListings(listingsChunk).then(function(result){
          console.log('Add Listings Function Called:');
        });
    }
    /* */
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOnRemoveFile = (data) => {
    console.log('---------------------------');
    console.log(data);
    console.log('---------------------------');
  };  

  render() {
    const { user, classes } = this.props;

    if (user && this.state.metadata) {

      var loadDate = moment.unix(this.state.metadata.loadDate.seconds);


      return (
        <>
        <CssBaseline />
        <Container maxWidth="sm">
          <h5>Click and Drag Upload</h5>
          <CSVReader
            onDrop={this.handleOnDrop}
            onError={this.handleOnError}
            addRemoveButton
            onRemoveFile={this.handleOnRemoveFile}
          >
            <span>Drop CSV file here or click to upload.</span>
          </CSVReader>

          <h5>Update Agents Object with Name Array (for search)</h5>
          <Button color='primary' variant="outlined" onClick={this.buildNameArray}>Update Agents</Button>
         

          <h5>Update Metadata</h5>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>

          <FormControl className={classes.metaInput} fullWidth>
            <KeyboardDatePicker
              inputVariant='outlined'
              margin="normal"
              variant="outlined" 
              id="loadDate"
              label="Load Date"
              format="MM/dd/yyyy"
              value={loadDate.format('MM/DD/YYYY')}
              onChange={this.handleLoadDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            </FormControl>


            <FormControl className={classes.metaInput} fullWidth>
            <KeyboardDatePicker
              inputVariant='outlined'
              margin="normal"
              variant="outlined" 
              id="newestDate"
              label="Newest Date"
              format="MM/dd/yyyy"
              value={this.state.metadata.newestDate}
              onChange={this.handleNewestDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            </FormControl>


            <FormControl className={classes.metaInput} fullWidth>
            <KeyboardDatePicker
              inputVariant='outlined'
              margin="normal"
              variant="outlined" 
              id="oldestDate"
              label="Oldest Date"
              format="MM/dd/yyyy"
              value={this.state.metadata.oldestDate}
              onChange={this.handleOldestDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            </FormControl>

            <FormControl className={classes.metaInput} fullWidth>
            <TextField
              id="listingCount"
              name="listingCount"
              margin="normal"
              variant="outlined" 
              fullWidth
              label="Transaction Count"
              type="number"
              value={this.state.metadata.listingCount}
              onChange={this.handleChange}
            />

            </FormControl>

            <FormControl className={classes.metaInput} fullWidth>
            <TextField
              id="agentCount"
              name="agentCount"
              margin="normal"
              variant="outlined" 
              fullWidth
              label="Agent Count"
              type="number"
              value={this.state.metadata.agentCount}
              onChange={this.handleChange}
            />

            </FormControl>


          </MuiPickersUtilsProvider>

          <Button color='primary' variant="outlined" onClick={this.updateMetadata}>Update Metadata</Button>
          </Container>


        </>
      )
    }

    return (
      <CircularProgress />
    );
  }

  componentDidMount() {
    this.signInWithEmailLink();
  }
}

LoadDataPage.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
};

export default withRouter(withStyles(styles)(LoadDataPage));

