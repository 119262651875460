import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { auth } from "../../firebase";
import moment from 'moment';
import authentication from "../../services/authentication";
import EmptyState from "../EmptyState";
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
//import { ReactComponent as CabinIllustration } from "../../illustrations/cabin.svg";
import { ReactComponent as InsertBlockIllustration } from "../../illustrations/insert-block.svg";
//import { CSVReader } from 'react-papaparse';

import Chip from '@material-ui/core/Chip';
import TodayIcon from '@material-ui/icons/Today';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import FaceIcon from '@material-ui/icons/Face';
import HomeWorkIcon from '@material-ui/icons/HomeWork';

import firebase, { firestore } from '../../firebase';
import "firebase/functions";

//import '../../../node_modules/react-vis/dist/style.css';
//import {XYPlot, LineSeries, VerticalBarSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, VerticalRectSeries, HorizontalRectSeries, HorizontalBarSeries} from 'react-vis';
import { Bar } from 'react-chartjs-2'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 1),
    maxWidth: 1200,
    marginLeft: 'auto',
    marginRight: 'auto',
    margin: theme.spacing(1),
  },
  nowrapCell: {
    whiteSpace: 'nowrap',
  },
  closeButton: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    padding: theme.spacing(1),
  },
  tableContainer: {
    maxHeight: 300,
  },
  center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
  },
  chartContainer: {
    height: 300,
    position: 'relative'
  },
  chartCanvasWrapper:{
    width: '100%',
    overflowX: 'scroll'
  },
  chartCanvas:{
    position: 'absolute',
    right: 0,
    top: 0
  },
  centerChart:{
    margin: 'auto',
    height: 300,
  },
  tableCellTitle: {
    fontWeight: 'bold',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableHeader: {
    margin: 0,
  }
});

const headCells = [
  { id: 'mls', numeric: false, disablePadding: false, label: 'MLS' },
  { id: 'address', numeric: false, disablePadding: false, label: 'Address' },
  { id: 'listingAgentFullName', numeric: false, disablePadding: false, label: 'Listing Agent' },
  { id: 'listingOfficeCode', numeric: false, disablePadding: false, label: 'Listing Office' },
  { id: 'buyersAgentFullName', numeric: false, disablePadding: false, label: 'Buyer Agent' },
  { id: 'buyersOfficeCode', numeric: false, disablePadding: false, label: 'Buyer Office' },
  { id: 'buildingDescription', numeric: false, disablePadding: false, label: 'Type' },
  { id: 'buildingSubType', numeric: false, disablePadding: false, label: 'Subtype' },
  { id: 'closePrice', numeric: true, disablePadding: false, label: 'Sold Price' },
  { id: 'coeAsDate', numeric: true, disablePadding: false, label: 'Sold Date' },
  { id: 'daysOnMarket', numeric: true, disablePadding: false, label: 'DOM' },
];

class HomePage extends Component {

  constructor(props) {
    super(props);

    var agentPublicID = null;
    if(props.match && props.match.params && props.match.params.agentPublicID){
      agentPublicID = props.match.params.agentPublicID;
      console.log("Agent Public ID: ", agentPublicID);
    }

    this.state = {
      metadata: null,
      loading: true,
      dataMonths: null,
      listings: [],
      agents: [],
      autocompleteOpen: false,
      selectedAgent: null,
      selectedAgentID: '',
      agentPublicID: agentPublicID,
      filteredListings: [],
      salesChart: [],
      filteredSales: 0,
      filteredSides: 0,
      filteredListingSides: 0,
      filteredSalesSides: 0,
      lastYearSalesAverage: 0, 
      lastYearListingsAverage: 0, 
      lastYearVolumeAverage: 0,
      order: 'asc', 
      orderBy: 'soldDate', 
      
      //TODO: ADD TO OUTPUT      
      agentInfo: null,
      count: 0,
      countListings: 0,
      countSales: 0,
      averageSalesPrice: 0,
      oldestSale: null,
      newestSale: null,
      cheapestSale: 0,
      mostExpensiveSale: 0,
      volumeListings: 0,
      volumeSales: 0
    }

    this.getMetadata();

    if(agentPublicID){
      this.getAgentById(agentPublicID)
    }

  }

  getMonths = (metadata) => {

    var oldestDate = moment(metadata.oldestDate);
    var newestDate = moment(metadata.newestDate);
    var difference = newestDate.diff(oldestDate, 'months');
    var monthList = [];
    var i;
    for(i=0; i < difference + 1; i++){
      var monthYear = moment(metadata.oldestDate).add(i, 'months');
      //console.log(monthYear.format('YYYYMM'));
      monthList.push(monthYear.format('YYYYMM'));
    }
    return(monthList)
  }

  getMetaDataOLD = () => {

    var that = this;
    var query = firestore.collection('metadata').doc('dataload');
    query.get()
    .then((metadataDoc) => {
      if(metadataDoc.exists){
        var metadata = metadataDoc.data();
        console.log(metadata);
        var oldestDate = moment(metadata.oldestDate);
        var newestDate = moment(metadata.newestDate);
        var difference = newestDate.diff(oldestDate, 'months');
        // console.log(oldestDate);
        // console.log(newestDate);
        // console.log(difference);
        var monthList = [];
        var i;
        for(i=0; i < difference + 1; i++){
          var monthYear = moment(metadata.oldestDate).add(i, 'months');
          //console.log(monthYear.format('YYYYMM'));
          monthList.push(monthYear.format('YYYYMM'));
        }
        //console.log(monthList);
        that.setState({metadata: metadata, dataMonths: monthList});
      } else {
        console.error("Unable to read metadata doc dataload does not exist.");
      }
    })
    .catch((error) => {
      console.error("Unable to read metadata: ", error);
    });
  }

  getMetadata = () => {
    var metadataUrl = 'https://us-central1-change-real-estate.cloudfunctions.net/lvrSyncMetadata';
    fetch(metadataUrl).then((response) => {
      response.json().then((responseData) => {
        var agentCount = 0;
        var listingCount = 0;
        var newestDate = '';
        var oldestDate = '';
        var lastSyncDate = '';
    
        console.log(responseData);

        responseData.forEach((doc) =>{
          if(doc.id === 'members'){
            //agentCount = doc.memberCount;
            agentCount = doc.activeMemberCount;
          }
          if(doc.id === 'properties'){
            listingCount = doc.listingCount;
            newestDate = moment(new firebase.firestore.Timestamp(doc.newestListingTimestamp._seconds, doc.newestListingTimestamp._nanoseconds).toDate()).format('MM/DD/YYYY');
            oldestDate = moment(new firebase.firestore.Timestamp(doc.oldestListingTimestamp._seconds, doc.oldestListingTimestamp._nanoseconds).toDate()).format('MM/DD/YYYY');
            lastSyncDate = moment(new firebase.firestore.Timestamp(doc.lastSyncTimestamp._seconds, doc.lastSyncTimestamp._nanoseconds).toDate()).format('MM/DD/YYYY');
          }
        });
    
        var metadata = {
          agentCount: agentCount,
          listingCount: listingCount,
          newestDate: newestDate,
          oldestDate: oldestDate,
          lastSyncDate: lastSyncDate
        };
    
        console.log(metadata)

        var dataMonths = this.getMonths(metadata); 
        this.setState({ metadata: metadata, dataMonths: dataMonths })
        return metadata;
      });  
    });
  }


  getListingsOLD = (agentPID) => {
    console.log('Getting listings for agentPID: ', agentPID);
    var that = this;
    var query = firestore.collection('listings').where('agents', 'array-contains', agentPID).orderBy('actualCloseDate', 'desc');
    var listings = [];
    var filteredListings = [];
    var dataMonths = this.state.dataMonths;
    var salesCounts = {};
    var listingsCounts = {};
    var volumeCounts = {};
    dataMonths.forEach( (value, index) => {
      salesCounts[value] = 0;
      listingsCounts[value] = 0;
      volumeCounts[value] = 0;
    });

    var salesChart = [];
    var totalSales = 0;
    var totalSides = 0;
    var totalSalesSides = 0;
    var totalListingSides = 0;
 
    query.get().then((snapshot) => {
        if(snapshot.size){
        snapshot.forEach(function(listingDoc){
          var listingObject = listingDoc.data();
          listingObject.id = listingDoc.id;

          var closeMoment = moment(new firebase.firestore.Timestamp(listingObject.actualCloseDate.seconds, listingObject.actualCloseDate.nanoseconds).toDate());
          //var closeMomentTemp = moment(new firebase.firestore.Timestamp(listingObject.actualCloseDate.seconds, listingObject.actualCloseDate.nanoseconds).toDate());
          //var closeMonthYear = closeMomentTemp.date(1).toDate();

          listingObject.listDate = moment(new firebase.firestore.Timestamp(listingObject.listDate.seconds, listingObject.listDate.nanoseconds).toDate()).format('MM/DD/YYYY'); //CONVERT TO FIREBASE TIMESTAMP
          listingObject.actualCloseDate = closeMoment.format('MM/DD/YYYY'); //CONVERT TO FIREBASE TIMESTAMP
          listingObject.coeAsDate = closeMoment.toDate();
          listingObject.coeYearMonth = parseInt(closeMoment.format('YYYYMM')); //CONVERT TO YYYYMM as integer
          listingObject.estimatedCloseDate = moment(new firebase.firestore.Timestamp(listingObject.estimatedCloseDate.seconds, listingObject.estimatedCloseDate.nanoseconds).toDate()).format('MM/DD/YYYY'); //CONVERT TO FIREBASE TIMESTAMP

          //console.log(closeMonthYear);
          if(listingObject.buyersAgentPID === agentPID){
            if(salesCounts.hasOwnProperty(listingObject.coeYearMonth)){
              salesCounts[listingObject.coeYearMonth] = salesCounts[listingObject.coeYearMonth] + 1;
            } else {
              salesCounts[listingObject.coeYearMonth] = 1;
            }
          }
          if(listingObject.listingAgentPID === agentPID){
            if(listingsCounts.hasOwnProperty(listingObject.coeYearMonth)){
              listingsCounts[listingObject.coeYearMonth] = listingsCounts[listingObject.coeYearMonth] + 1;
            } else {
              listingsCounts[listingObject.coeYearMonth] = 1;
            }
          }
          if(volumeCounts.hasOwnProperty(listingObject.coeYearMonth)){
            volumeCounts[listingObject.coeYearMonth] = volumeCounts[listingObject.coeYearMonth] + listingObject.closePrice;
          } else {
            volumeCounts[listingObject.coeYearMonth] =  + listingObject.closePrice;
          }

          listings.push(listingObject);
          filteredListings.push(listingObject);
          totalSales += listingObject.closePrice;
          if(listingObject.listingAgentPID === agentPID){
            totalListingSides++;
            totalSides++;
          }
          if(listingObject.buyersAgentPID === agentPID){
            totalSalesSides++;
            totalSides++
          }
        });
      }
      //console.log(salesCounts);
      //console.log(volumeCounts);

      console.log('Sales Counts: ', salesCounts);
      console.log('Listing Counts: ', listingsCounts);
      console.log('Volume Counts: ', volumeCounts);

      const last12SalesCounts = Object.values(salesCounts).slice(-12);
      const last12ListingsCounts = Object.values(listingsCounts).slice(-12);
      const last12VolumeCounts = Object.values(volumeCounts).slice(-12);

      var lastYearSalesCount = last12SalesCounts.reduce((a, b) => a + b, 0);
      var lastYearListingsCount = last12ListingsCounts.reduce((a, b) => a + b, 0);
      var lastYearVolumeCount = last12VolumeCounts.reduce((a, b) => a + b, 0);

      console.log('Sales Counts: ', lastYearSalesCount);
      console.log('Listing Counts: ', lastYearListingsCount);
      console.log('Volume Counts: ', lastYearVolumeCount);


      var lastYearSalesAverage = lastYearSalesCount / 12;
      var lastYearListingsAverage = lastYearListingsCount / 12;
      var lastYearVolumeAverage = lastYearVolumeCount / 12;

      console.log('Sales Average: ', lastYearSalesAverage);
      console.log('Listing Average: ', lastYearListingsAverage);
      console.log('Volume Average: ', lastYearVolumeAverage);

      var salesLabels = [];
      var salesData = [];
      var listingsLabels = [];
      var listingsData = [];
      var volumeLabels = [];
      var volumeData = [];
      for (const [key, value] of Object.entries(salesCounts)) {
        salesLabels.push(key.substring(0,4) + "-" + key.substring(4,6));
        salesData.push(value);
      }
      for (const [key, value] of Object.entries(listingsCounts)) {
        listingsLabels.push(key);
        listingsData.push(value);
      }
      for (const [key, value] of Object.entries(volumeCounts)) {
        volumeLabels.push(key);
        volumeData.push(value);
      }

      salesChart = {
        labels: salesLabels,
        
        datasets: [
          {
            type: 'line',
            label: "Sales Volume",
            data: volumeData,
            fill: false,
            backgroundColor: 'rgb(0, 0, 0, 0.9)',
            borderColor: 'rgba(0, 0, 0, 1.0)',          
            yAxisID: 'y-axis-1',
          },
          {
          type: 'bar',
          label: "# of Listings",
          data: listingsData,
          borderWidth: 1,
          backgroundColor: 'rgb(54, 162, 235)',
          borderColor: 'rgba(54, 162, 235, 0.2)',          
          yAxisID: 'y-axis-2',
        },
        {
          type: 'bar',
          label: "# of Sales",
          data: salesData,
          borderWidth: 1,
          backgroundColor: 'rgb(255, 0, 235)',
          borderColor: 'rgba(255, 0, 235, 0.2)',          
          yAxisID: 'y-axis-2',
        },
      ]
      }
      //console.log(salesChart);
    
      that.setState({listings: listings, 
        filteredListings: filteredListings, 
        filteredSales: totalSales, 
        salesChart: salesChart, 
        filteredSides: totalSides, 
        filteredListingSides: totalListingSides, 
        filteredSalesSides: totalSalesSides, 
        lastYearSalesAverage: lastYearSalesAverage, 
        lastYearListingsAverage: lastYearListingsAverage, 
        lastYearVolumeAverage: lastYearVolumeAverage});
    });
  };  


  getListings = async(agentPID) => {
    console.log('Getting listings for agentPID: ', agentPID);
    var listings = [];
    var filteredListings = [];
    var dataMonths = this.state.dataMonths;
    var salesCounts = {};
    var listingsCounts = {};
    var volumeCounts = {};
    dataMonths.forEach( (value, index) => {
      salesCounts[value] = 0;
      listingsCounts[value] = 0;
      volumeCounts[value] = 0;
    });

    var salesChart = [];
    var totalSales = 0;
    var totalSides = 0;
    var totalSalesSides = 0;
    var totalListingSides = 0;
 
    var salesLookupUrl = 'https://us-central1-change-real-estate.cloudfunctions.net/lvrSoldPropertyByAgent?agentMLSID=' + agentPID;
    var responseData = {};
    var response = await fetch(salesLookupUrl);
    responseData = await response.json();

    var totalSalesVolume = responseData.totalSalesVolume;
    var countListings = responseData.countListings;
    var averageListings = responseData.averageListings;
    var countSales = responseData.countSales;
    var averageSales = responseData.averageSales;
    var sales = responseData.sales;

    var salesLabels = [];
    var salesData = [];
    var listingsLabels = [];
    var listingsData = [];
    var volumeLabels = [];
    var volumeData = [];

    sales.forEach((listingSummary) => {
      var listingObject = listingSummary;
      listingObject.id = listingSummary.ListingId;
      var coeYear = listingSummary.CloseDate.substring(0,4);
      var coeMonth = listingSummary.CloseDate.substring(5,7);
      listingObject.coeYearMonth = coeYear + coeMonth;
      listingObject.coeYear = coeYear;
      listingObject.coeMonth = coeMonth;
      //console.log("Listing COE Year: ", coeYear, " Listing COE Month: ", coeMonth, " Listing: ", listingObject);


      if(listingObject.BuyerAgentMlsId === agentPID.toString()){
        if(salesCounts.hasOwnProperty(listingObject.coeYearMonth)){
          salesCounts[listingObject.coeYearMonth] = salesCounts[listingObject.coeYearMonth] + 1;
        } else {
          salesCounts[listingObject.coeYearMonth] = 1;
        }
      }
      if(listingObject.ListAgentMlsId === agentPID.toString()){
        if(listingsCounts.hasOwnProperty(listingObject.coeYearMonth)){
          listingsCounts[listingObject.coeYearMonth] = listingsCounts[listingObject.coeYearMonth] + 1;
        } else {
          listingsCounts[listingObject.coeYearMonth] = 1;
        }
      }
      if(volumeCounts.hasOwnProperty(listingObject.coeYearMonth)){
        volumeCounts[listingObject.coeYearMonth] = volumeCounts[listingObject.coeYearMonth] + listingObject.ClosePrice;
      } else {
        volumeCounts[listingObject.coeYearMonth] =  + listingObject.ClosePrice;
      }


      listings.push(listingObject);
      filteredListings.push(listingObject);
      totalSales += listingObject.ClosePrice;
      if(listingObject.ListAgentMlsId === agentPID){
        totalListingSides++;
        totalSides++;
      }
      if(listingObject.BuyerAgentMlsId === agentPID){
        totalSalesSides++;
        totalSides++
      }
    });

    var cleanSalesCounts = {};
    var cleanListingCounts = {};
    var cleanVolumeCounts = {};
    var checkForZero = true
    for (const [key, value] of Object.entries(volumeCounts)) {
      if(checkForZero && value === 0){
        continue;
      } else {
        cleanVolumeCounts[key] = value;
        cleanSalesCounts[key] = salesCounts[key];
        cleanListingCounts[key] = listingsCounts[key];
        checkForZero = false;
      }
    }

    for (const [key, value] of Object.entries(cleanSalesCounts)) {
      salesLabels.push(key.substring(0,4) + "-" + key.substring(4,6));
      salesData.push(value);
    }
    for (const [key, value] of Object.entries(cleanListingCounts)) {
      listingsLabels.push(key);
      listingsData.push(value);
    }
    for (const [key, value] of Object.entries(cleanVolumeCounts)) {
      volumeLabels.push(key);
      volumeData.push(value);
    }

    salesChart = {
      labels: salesLabels,
      datasets: [
        {
          type: 'line',
          label: "Sales Volume",
          data: volumeData,
          fill: false,
          backgroundColor: 'rgb(0, 0, 0, 0.9)',
          borderColor: 'rgba(0, 0, 0, 1.0)',          
          yAxisID: 'y-axis-1',
        },
        {
        type: 'bar',
        label: "# of Listings",
        data: listingsData,
        borderWidth: 1,
        backgroundColor: 'rgb(54, 162, 235)',
        borderColor: 'rgba(54, 162, 235, 0.2)',          
        yAxisID: 'y-axis-2',
      },
      {
        type: 'bar',
        label: "# of Sales",
        data: salesData,
        borderWidth: 1,
        backgroundColor: 'rgb(255, 0, 235)',
        borderColor: 'rgba(255, 0, 235, 0.2)',          
        yAxisID: 'y-axis-2',
      },
    ]
    }    

    this.setState({
      loading: false,
      listings: listings, 
      filteredListings: filteredListings, 
      filteredSales: totalSalesVolume, 
      salesChart: salesChart, 
      filteredSides: countListings + countSales, 
      filteredListingSides: countListings, 
      filteredSalesSides: countSales, 
      lastYearSalesAverage: parseFloat(averageSales), 
      lastYearListingsAverage: parseFloat(averageListings), 
      lastYearVolumeAverage: totalSalesVolume,
      agentInfo: responseData.agentInfo,
      count: responseData.count,
      countListings: responseData.countListings,
      countSales: responseData.countSales,
      averageSalesPrice: responseData.averageSalesPrice,
      oldestSale: responseData.oldestSale,
      newestSale: responseData.newestSale,
      cheapestSale: responseData.cheapestSale,
      mostExpensiveSale: responseData.mostExpensiveSale,
      volumeListings: responseData.volumeListings,
      volumeSales: responseData.volumeSales
    });

  };  


  getAgentByIdOLD = (agentPublicID) => {
    var that = this;
    var query = firestore.collection('agents').doc(agentPublicID);
    query.get().then((doc) => {
        if(doc.exists){
          var selectedAgent = doc.data();
          console.log('Selecting agent...');
          this.getListings(selectedAgent.agentPID);
          this.setState({selectedAgentID: selectedAgent.agentPID, selectedAgent: selectedAgent, agents: [selectedAgent]});
        }
      })
  };  

  getAgentById = async(agentPublicID) => {
    if (agentPublicID.length > 3){
      var agentLookupUrl = 'https://us-central1-change-real-estate.cloudfunctions.net/lvrMemberSearch?searchText=' + agentPublicID;
      console.log(agentLookupUrl);
      var responseData = {};
      var response = await fetch(agentLookupUrl);
      responseData = await response.json();
      var selectedAgent = responseData[0];
      console.log(selectedAgent);
      this.getListings(selectedAgent.MemberMlsId);
      this.setState({loading: true, selectedAgentID: selectedAgent.MemberMlsId, selectedAgent: selectedAgent, agents: [selectedAgent]});
    }

  }

  signInWithEmailLink = () => {
    const { user } = this.props;

    if (user) {
      return;
    }

    const emailLink = window.location.href;

    if (!emailLink) {
      return;
    }

    if (auth.isSignInWithEmailLink(emailLink)) {
      let emailAddress = localStorage.getItem("emailAddress");

      if (!emailAddress) {
        this.props.history.push("/");

        return;
      }

      authentication
        .signInWithEmailLink(emailAddress, emailLink)
        .then((value) => {
          const user = value.user;
          const displayName = user.displayName;
          const emailAddress = user.email;

          this.props.openSnackbar(
            `Signed in as ${displayName || emailAddress}`
          );
        })
        .catch((reason) => {
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            case "auth/expired-action-code":
            case "auth/invalid-email":
            case "auth/user-disabled":
              this.props.openSnackbar(message);
              break;

            default:
              this.props.openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          this.props.history.push("/");
        });
    }
  };

  // Function to convert 
  convertCurrencyToFloat = (currency) => { 
      
    if(currency){
      var temp = currency.replace(/[^0-9.-]+/g,""); 
      return parseFloat(temp); 
    } else {
      return(currency);
    }
      
  } 

  handleAgentSelect = (event, value) => {
    if(!value){
      return;
    }
    console.log('------AGENT SELECTED-------');
    console.log(value);
    console.log('---------------------------');

    this.getListings(value.MemberMlsId);
    this.setState({loading: true, selectedAgentID: value.MemberMlsId, selectedAgent: value});
  }


  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } 

  desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
  getSorting = (order, orderBy) => {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    const order = isAsc ? 'desc' : 'asc';
    this.setState({order: order, orderBy: property});
  };

  filterAgentListOLD = async (searchText) => {
    console.log("Input Value: ", searchText);

    var resultList = [];

    //var query = firestore.collection('agents').where('agentFullName', '>=', searchText).where('agentFullName', '<=', searchText + '\uf8ff');
    var query = firestore.collection('agents').where("nameArray", "array-contains", searchText.toLowerCase());
    var result = await query.get();

    result.forEach( (agent) => {
      resultList.push(agent.data());
    });    

    console.log('getAgentsByName Result');
    console.log(resultList);

    this.setState({agents: resultList});
  }

  filterAgentList = async (searchText) => {
    if (searchText.length > 2){
      var agentLookupUrl = 'https://us-central1-change-real-estate.cloudfunctions.net/lvrMemberSearch?searchText=' + searchText;
      var responseData = {};
      var response = await fetch(agentLookupUrl);
      responseData = await response.json();
      //console.log(responseData)
      this.setState({agents: responseData});
    }
  }

  getOptionLabel = (option) => {

    //console.log("Option: ", option);

    if (option // 👈 null and undefined check
    && Object.keys(option).length !== 0
    && Object.getPrototypeOf(option) === Object.prototype) {
      return option.MemberFullName;
    } else {
      return '';
    }

  }

 
  render() {
    const { user, classes } = this.props;

    if(this.state.metadata === null){
      return(
        <Container maxWidth="sm">
          <div className={classes.center}>
            <Typography variant="h5"  component="h3">Loading...</Typography>
            <br />
            <br />
            <CircularProgress />
          </div>
        </Container>
      )
    }


    const order = this.state.order;
    const orderBy = this.state.orderBy;
    const loading = this.state.autocompleteOpen && this.state.agents.length === 0;

    const createSortHandler = property => event => {
      this.handleRequestSort(event, property);
    };

    var currencyFormatter = {
      style: "currency",
      currency: "USD"
    }

    const chartOptions = {
      scales: {
        yAxes: [
          {
            type: 'linear',
            ticks: {  
              beginAtZero: true,  
              
              callback: function(value, index, ticks) {
                return '$' + value.toLocaleString();
              },
            },
            display: true,
            position: 'left',
            id: 'y-axis-1',
          },
          {
            type: 'linear',
            stacked: true,
            ticks: {  beginAtZero: true,  },
            display: true,
            position: 'right',
            id: 'y-axis-2',
            gridLines: {
              drawOnArea: false,
            },
          },
        ],
        xAxes: [{stacked: true}],
        tooltips: {
          callbacks: {

            label: function (tooltipItem, data) {
              var tooltipValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              return parseInt(tooltipValue).toLocaleString();
            }
                  
            //label: function(tooltipItem, data){
            //  var label = data.datasets[tooltipItem.datasetIndex].label || '';
            //  console.log('** LABEL *** ', label);
            //  return label;
            //}
            /*   
              label: function(context) {
                let label = context.dataset.label || '';

                if (label) {
                    label += ': ';
                }
                if (context.parsed.y !== null) {
                    label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                }
                return label;            
            }
            */
          }
        }
      },

      maintainAspectRatio: false,
    }
    

    //if (user && this.state.agents !== null) {
    if (user) {

      /*
      if(this.state.agents.length === 0){
        return(
          <Container maxWidth="sm">
            <div className={classes.center}>
              <Typography variant="h5"  component="h3">Loading agents...</Typography>
              <br />
              <br />
              <CircularProgress />
            </div>
          </Container>
        )
      }
      */




      return (
        <>
        <CssBaseline />
        <Container maxWidth="md">
          <br></br>
          <Typography component="div">
            <Chip className={classes.metachip} variant="outlined" size="small" icon={<TodayIcon />} label={"Oldest Date: " + this.state.metadata.oldestDate} />
            <Chip className={classes.metachip} variant="outlined" size="small" icon={<InsertInvitationIcon />} label={"Most Recent Date: " + this.state.metadata.newestDate} />
            <Chip className={classes.metachip} variant="outlined" size="small" icon={<HomeWorkIcon />} label={"Listing Count: " + this.numberWithCommas(this.state.metadata.listingCount)} />
            <Chip className={classes.metachip} variant="outlined" size="small" icon={<FaceIcon />} label={"Active Agent Count: " + this.numberWithCommas(this.state.metadata.agentCount)}  />
          </Typography>
          <br></br>

          { this.state.selectedAgent
          ?
          <Autocomplete
            id="agentAutoComplete"
            open={this.state.autocompleteOpen}
            onOpen={() => this.setState({autocompleteOpen: true})}
            onClose={() => this.setState({autocompleteOpen: false})}
            options={this.state.agents}
            //filterOptions={this.filterAgentList}
            loading={loading}
            value={this.state.selectedAgent}
            getOptionSelected={(option, value) => option.MemberFullName === value.MemberFullName}
            getOptionLabel={(option) => option.MemberFullName}
            onChange={this.handleAgentSelect}
            style={{ width: 360 }}
            renderInput={(params) => 
            <TextField {...params} 
            label="Search for an agent by first or last name" 
            variant="outlined" 
              onChange = {event => {
                if(event.target.value !== '' || event.target.value !== null || event.target.value.length < 2){
                  this.filterAgentList(event.target.value)
                }
              }}
              InputProps={{...params.InputProps, endAdornment: (
                 <React.Fragment>
                   {loading ? ( <CircularProgress color="inherit" size={20} />) : null}
                   {params.InputProps.endAdornment}
                 </React.Fragment>
              )}}
            />}
          />
          :
          <Autocomplete
            id="agentAutoComplete"
            open={this.state.autocompleteOpen}
            onOpen={() => this.setState({autocompleteOpen: true})}
            onClose={() => this.setState({autocompleteOpen: false})}
            options={this.state.agents}
            loading={loading}
            value=''
            getOptionSelected={(option, value) => option.MemberFullName === value.MemberFullName}
            getOptionLabel={this.getOptionLabel}
            onChange={this.handleAgentSelect}
            style={{ width: 360 }}
            renderInput={(params) => 
              <TextField {...params} 
                label="Search for an agent by first or last name" 
                variant="outlined" 
                onChange = {event => {
                  if(event.target.value !== '' || event.target.value !== null || event.target.value.length < 2){
                    this.filterAgentList(event.target.value)
                  }
                }}
                InputProps={{...params.InputProps, endAdornment: (
                   <React.Fragment>
                     {loading ? ( <CircularProgress color="inherit" size={20} />) : null}
                     {params.InputProps.endAdornment}
                   </React.Fragment>
                )}}
              />}
          />
          }
          { (this.state.loading && this.state.selectedAgentID) &&
              <Container maxWidth="sm">
              <div className={classes.center}>
                <Typography variant="h5"  component="h3">Loading Listings...</Typography>
                <br />
                <br />
                <CircularProgress />
              </div>
            </Container>                        
          }
          { (this.state.loading === false && this.state.selectedAgentID)  &&
          <>
          <br />
          <br />
          <TableContainer component={Paper}>
            <Table  size="small" aria-label="a dense table">
              <Hidden xsDown> 
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <h3 className={classes.tableHeader}>{this.state.selectedAgent.MemberFullName}</h3>
                      <h5 className={classes.tableHeader}>{this.state.selectedAgent.OfficeName} ({this.state.selectedAgent.OfficeMlsId})</h5>
                      <h5 className={classes.tableHeader}>Status: {this.state.selectedAgent.MemberStatus}</h5>
                    </TableCell>
                  </TableRow>
                </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>Public ID</TableCell>
                  <TableCell align="right"><a title="Permalink" href={"/agent/" + this.state.selectedAgentID}>{this.state.selectedAgentID}</a></TableCell>
                  <TableCell className={classes.tableCellTitle}>Transaction Count</TableCell>
                  <TableCell align="right">{this.state.filteredListings.length}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>Phone</TableCell>
                  <TableCell align="right">{this.state.selectedAgent.MemberPreferredPhone}</TableCell>
                  <TableCell className={classes.tableCellTitle}>Sides Count</TableCell>
                  <TableCell align="right">{this.state.filteredSides}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>Email</TableCell>
                  <TableCell align="right"><a href={'mailto:' + this.state.selectedAgent.MemberEmail}>{this.state.selectedAgent.MemberEmail}</a></TableCell>
                  <TableCell className={classes.tableCellTitle}>Listing Sides Count</TableCell>
                  <TableCell align="right">{this.state.countListings}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>Sales Volume</TableCell>
                  <TableCell align="right">{this.state.filteredSales.toLocaleString("en-US", currencyFormatter)}</TableCell>
                  <TableCell className={classes.tableCellTitle}>Listings Avg. Price</TableCell>
                  <TableCell align="right">{this.state.lastYearListingsAverage.toLocaleString("en-US", currencyFormatter)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>Most Expensive Sale</TableCell>
                  <TableCell align="right">{ this.state.mostExpensiveSale.toLocaleString("en-US", currencyFormatter) }</TableCell>
                  <TableCell className={classes.tableCellTitle}>Sales Sides Count</TableCell>
                  <TableCell align="right">{this.state.countSales}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>Least Expensive Sale</TableCell>
                  <TableCell align="right">{ this.state.cheapestSale.toLocaleString("en-US", currencyFormatter)}</TableCell>
                  <TableCell className={classes.tableCellTitle}>Sales Avg. Price</TableCell>
                  <TableCell align="right">{this.state.lastYearSalesAverage.toLocaleString("en-US", currencyFormatter)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>Oldest Sale</TableCell>
                  <TableCell align="right">{ this.state.oldestSale }</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>Most Recent Sale</TableCell>
                  <TableCell align="right">{ this.state.newestSale }</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
              </Hidden>
              <Hidden smUp> 
              <TableBody>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>Public ID</TableCell>
                  <TableCell align="right">{this.state.selectedAgentID}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>Phone</TableCell>
                  <TableCell align="right">{this.state.selectedAgent.MemberPreferredPhone}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>Email</TableCell>
                  <TableCell align="right"><a href={'mailto:' + this.state.selectedAgent.MemberEmail}>{this.state.selectedAgent.MemberEmail}</a></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>Sales Volume</TableCell>
                  <TableCell align="right">{this.state.filteredSales.toLocaleString("en-US", currencyFormatter)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>Most Expensive Sale</TableCell>
                  <TableCell align="right">{ this.state.mostExpensiveSale.toLocaleString("en-US", currencyFormatter) }</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>Least Expensive Sale</TableCell>
                  <TableCell align="right">{ this.state.cheapestSale.toLocaleString("en-US", currencyFormatter)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>Oldest Sale</TableCell>
                  <TableCell align="right">{ this.state.oldestSale }</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>Most Recent Sale</TableCell>
                  <TableCell align="right">{ this.state.newestSale }</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
                <TableRow>
                <TableCell className={classes.tableCellTitle}>Transaction Count</TableCell>
                  <TableCell align="right">{this.state.filteredListings.length}</TableCell>
                </TableRow>
                <TableRow>
                <TableCell className={classes.tableCellTitle}>Sides Count</TableCell>
                  <TableCell align="right">{this.state.filteredSides}</TableCell>
                </TableRow>
                <TableRow>
                <TableCell className={classes.tableCellTitle}>Listing Sides Count</TableCell>
                  <TableCell align="right">{this.state.countListings}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>Listings Avg. Price</TableCell>
                  <TableCell align="right">{this.state.lastYearListingsAverage.toLocaleString("en-US", currencyFormatter)}</TableCell>
                </TableRow>
                <TableRow>
                <TableCell className={classes.tableCellTitle}>Sales Sides Count</TableCell>
                  <TableCell align="right">{this.state.countSales}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>Sales Avg. Price</TableCell>
                  <TableCell align="right">{this.state.lastYearSalesAverage.toLocaleString("en-US", currencyFormatter)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan="2"></TableCell>
                </TableRow>
              </TableBody>
              </Hidden>
            </Table>
            </TableContainer>

          </>
          }
          </Container>        

          <br />
          <br />

          { (this.state.loading === false && this.state.salesChart && this.state.salesChart.hasOwnProperty('labels') && this.state.selectedAgentID)  &&
          <>
          <Container maxWidth="xl" className={classes.chartContainer}>
            <Paper className={classes.chartCanvasWrapper}>
              <Bar className={classes.chartCanvas} data={this.state.salesChart} options={chartOptions}  height={300} />
            </Paper>
          </Container>

          <br />
          <br />
          </>
          }

          { this.state.loading === false && this.state.selectedAgentID  &&
          <Container maxWidth="xl">
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>

                { headCells.map(headCell => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}

                { false && 
                  <>
                  <TableCell>MLS</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Listing Agent</TableCell>
                  <TableCell>Listing Office</TableCell>
                  <TableCell>Buyer Agent</TableCell>
                  <TableCell>Buyer Office</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Subtype</TableCell>
                  <TableCell align="right">Sold Price</TableCell>
                  <TableCell align="right">Sold Date</TableCell>
                  <TableCell align="right">DOM</TableCell>
                  </>
                }
                </TableRow>
              </TableHead>
              <TableBody>
                {this.stableSort(this.state.filteredListings, this.getSorting(this.state.order, this.state.orderBy)).map((listing) => (
                  <TableRow key={listing.ListingId}>
                    <TableCell component="th" scope="row">
                      <a href={ "https://changevegas.com/listing/" + listing.ListingId + "/"} target='_blank' rel="noreferrer">
                      {listing.ListingId}
                      </a>
                    </TableCell>
                    <TableCell>{listing.AddressFull}</TableCell>
                    <TableCell>{listing.ListAgentFullName}</TableCell>
                    <TableCell>{listing.ListOfficeMlsId}</TableCell>
                    <TableCell>{listing.BuyerAgentFullName}</TableCell>
                    <TableCell>{listing.BuyerOfficeMlsId}</TableCell>
                    <TableCell>{listing.PropertyType}</TableCell>
                    <TableCell>{listing.PropertySubType}</TableCell>
                    { listing.ClosePrice ?
                      <TableCell align="right">{listing.ClosePrice.toLocaleString("en-US", currencyFormatter)}</TableCell>
                      :
                      <TableCell align="right">{listing.ClosePrice}</TableCell>
                    }
                    <TableCell align="right">{listing.CloseDate}</TableCell>
                    <TableCell align="right">{listing.DaysOnMarket}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </Container>
          }

        </>
      );
    }

    return (
      <EmptyState
        image={<InsertBlockIllustration />}
        title="Lender MVP"
        description="Simple lender tool for reviewing agent production."
      />
    );
  }

  componentDidMount() {
    this.signInWithEmailLink();
  }
}

HomePage.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object
};

export default withRouter(withStyles(styles)(HomePage));

